/*
 * @Descripttion: 修改表格滚动条之后表格不对其处理
 * @Author: renmingming
 * @Date: 2022-03-17 10:28:10
 * @LastEditors: renmingming
 * @LastEditTime: 2022-03-17 11:10:30
 */

import { getScrollWidth } from '@/utils/index';
export default {
  name: 'tableAlignCalc',
  componentUpdated: function (el, binding) {
    const scrollWidth = getScrollWidth();
    const elWidth = el.clientWidth - scrollWidth;
    const tableEl = el.querySelector(
      '.ant-table-header table'
    ) as HTMLTableElement;
    if (tableEl) {
      tableEl.style.width = `${elWidth}px`;
      const tableBody = el.querySelector('.ant-table-body') as HTMLDivElement;
      const bodyEl = tableBody.querySelector('table') as HTMLTableElement;
      const maxHeight = window
        .getComputedStyle(tableBody, null)
        .getPropertyValue('max-height');
      if (bodyEl.clientHeight < parseInt(maxHeight)) {
        tableBody.style.overflow = 'auto';
        tableEl.style.width = `${elWidth + scrollWidth}px`;
      }
      bodyEl.style.width = `${elWidth}px`;
    }
  },
};
