/*
 * @Descripttion:
 * @Author: renmingming
 * @Date: 2021-10-14 14:41:58
 * @LastEditors: renmingming
 * @LastEditTime: 2022-03-17 10:54:08
 */
import Vue from 'vue';
import { SummaryItem, TotalPriceItem } from '@/interface/product';
import tableAlignCalc from '@/directives/tableAlignCalc';
import { mapGetters } from 'vuex';
import { TableCalcStatus } from '@/interface';
export default Vue.extend({
  data() {
    return {
      tableHeadHeight: 0,
      updateTableCalc: '', // 用作更新表格计算
    };
  },
  props: {
    componentHeight: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapGetters(['isRetractUnfold']),
    tableScroll() {
      const num: number = this.componentHeight - this.tableHeadHeight - 3;
      return { y: num, x: 'max-content' };
    },
  },
  watch: {
    isRetractUnfold: function (val, oldVal) {
      if (val !== oldVal) {
        this.updateTableCalc = val
          ? TableCalcStatus.REDUCE
          : TableCalcStatus.ENLARGE;
      }
    },
  },
  mounted() {
    this.setTableHeadHeight();
  },
  methods: {
    /**
     * 设置表格内容高度
     */
    setTableHeadHeight() {
      this.$nextTick(() => {
        const tableHeadHeight: number = (
          document.querySelector('.ant-table-thead') as HTMLElement
        ).clientHeight;
        console.log(tableHeadHeight);
        this.tableHeadHeight = tableHeadHeight;
      });
    },
    /**
     * 表格行类名
     * @param record
     * @returns
     */
    rowClassName(record: TotalPriceItem | SummaryItem) {
      if (record.dispNo && record.dispNo.split('.').length <= 1) {
        return 'project-detail-table-highlight';
      }
      return '';
    },
  },
  directives: {
    tableAlignCalc,
  },
});
