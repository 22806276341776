

































import Vue from 'vue';
import mixin from './mixin';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { TotalPriceItem } from '@/interface/product';
import { TableCalcStatus } from '@/interface';
import { subtract } from '@/utils';
import { coccMtdsResource_1_project_constructsBySequenceNbrSummary } from '@/api/projectDetail';

const columns = [
  {
    dataIndex: 'dispNo',
    align: 'center',
    width: 70,
    slots: { title: 'customTitle' },
    customCell: () => {
      return { style: { background: '#F5F7F9' } };
    },
  },
  {
    title: '名称',
    dataIndex: 'projectName',
    align: 'center',
    scopedSlots: { customRender: 'projectName' },
  },
  {
    title: '金额(元)',
    dataIndex: 'total',
    align: 'center',
    scopedSlots: { customRender: 'total' },
  },
  {
    title: '规费(元)',
    dataIndex: 'gfee',
    align: 'center',
  },
  {
    title: '安全生产、文明施工费(元)',
    dataIndex: 'safeFee',
    align: 'center',
  },
];
export default mixin.extend({
  name: 'projectTotalPrice',
  mixins: [mixin],
  data() {
    const projectTotalPriceData: TotalPriceItem[] = [];
    return {
      columns,
      projectTotalPriceData,
      tabelHeadHeight: 0,
    };
  },
  computed: {
    ...mapGetters([
      'sequenceNbr',
      'bidSequenceNbr',
      'combinedPriceStatus',
      'toSeeReportForms',
    ]),
  },
  watch: {
    bidSequenceNbr: function (val, oldVal) {
      if (val !== oldVal) {
        this.getTotalData();
      }
    },
    toSeeReportForms: function (val) {
      if (val) {
        this.getTotalData();
        this.SET_TOSEEREPORTFORMS(false);
      }
    },
  },
  created() {
    this.getTotalData();
    // window.addEventListener('beforeunload', this.syncTriggerGenerationPdf);
  },
  methods: {
    ...mapActions(['getProjectDetail', 'syncTriggerGenerationPdf']),
    ...mapMutations(['SET_TOSEEREPORTFORMS']),
    getTotalData() {
      const sequenceNbr = this.bidSequenceNbr || this.sequenceNbr;
      if (!sequenceNbr) return '';
      coccMtdsResource_1_project_constructsBySequenceNbrSummary(
        sequenceNbr
      ).then((res) => {
        if (res.status === 200) {
          this.handleProjectTotal(res.result);
        }
      });
    },
    handleProjectTotal(data: TotalPriceItem[]) {
      let list: TotalPriceItem[] = [];
      data.forEach((item) => {
        const total = item.total === null ? '' : item.total;
        const gfee = item.gfee === null ? '' : item.gfee;
        const safeFee = item.safeFee === null ? '' : item.safeFee;
        const sbf = item.sbf === null ? '' : item.sbf;
        const projectTotal =
          subtract(Number(item.total), Number(item.sbf)) || '';
        let itemList = [
          {
            dispNo: `${item.sortNo}`,
            projectName: `${item.projectName}`,
            total: total,
            gfee,
            safeFee,
          },
          {
            dispNo: `${item.sortNo}.1`,
            projectName: `工程费`,
            total: projectTotal,
            gfee,
            safeFee,
          },
          {
            dispNo: `${item.sortNo}.2`,
            projectName: `设备费及税金`,
            total: sbf,
            gfee: ``,
            safeFee: ``,
            sequenceNbr: item.sequenceNbr,
          },
        ];
        list = [...list, ...itemList];
      });
      this.projectTotalPriceData = list;
      this.updateTableCalc = TableCalcStatus.UPDATE;
    },
  },
  beforeDestroy() {
    // window.removeEventListener('beforeunload', this.syncTriggerGenerationPdf);
    // this.syncTriggerGenerationPdf();
  },
});
